<template>
  <div>
    <div class="site-wrapper clearfix">
      <div class="site-overlay"></div>
      <div class="header-mobile clearfix" id="header-mobile">
        <div class="header-mobile__logo">
          <a href="/">
            <img src="/assets/images/esports/logo.png" width="265" height="69" alt="Clash of Clans Wiki" class="header-mobile__logo-img"/>
          </a>
        </div>
        <div class="header-mobile__inner">
          <a id="header-mobile__toggle" class="burger-menu-icon">
            <span class="burger-menu-icon__line"></span>
          </a>
          <span
            class="header-mobile__search-icon"
            id="header-mobile__search-icon"
          ></span>
        </div>
      </div>
      <header class="header header--layout-3">
        <div class="header__top-bar clearfix">
          <div class="container">
            <div class="header__top-bar-inner">
              <ul
                class="social-links social-links--inline social-links--main-nav social-links--top-bar"
              >
                <li class="social-links__item">
                  <a
                    href="https://www.youtube.com/GameXO?sub_confirmation=1"
                    class="social-links__link"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Facebook"
                    ><i class="fab fa-facebook"></i
                  ></a>
                </li>
                <li class="social-links__item">
                  <a
                    href="https://www.youtube.com/GameXO?sub_confirmation=1"
                    class="social-links__link"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Twitter"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li class="social-links__item">
                  <a
                    href="https://www.youtube.com/GameXO?sub_confirmation=1"
                    class="social-links__link"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Twitch"
                    ><i class="fab fa-twitch"></i
                  ></a>
                </li>
                <li class="social-links__item">
                  <a
                    href="https://www.youtube.com/GameXO?sub_confirmation=1"
                    class="social-links__link"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="YouTube"
                    ><i class="fab fa-youtube"></i
                  ></a>
                </li>
                <li class="social-links__item">
                  <a
                    href="https://www.youtube.com/GameXO?sub_confirmation=1"
                    class="social-links__link"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Google+"
                    ><i class="fab fa-google-plus-g"></i
                  ></a>
                </li>
                <li class="social-links__item">
                  <a
                    href="https://www.youtube.com/GameXO?sub_confirmation=1"
                    class="social-links__link"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Instagram"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
              </ul>
              <ul class="nav-account">
                <li class="nav-account__item">
                  <a href="#">Language: <span class="highlight">EN</span></a>
                  <ul class="main-nav__sub">
                    <li><a href="#">English</a></li>
                    <li><a href="#">Vietnamese</a></li>
                    <li><a href="#">French</a></li>
                    <li><a href="#">German</a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="header__primary">
          <div class="container">
            <div class="header__primary-inner">
              <div class="header-logo">
                <a href="/"
                  >
                  <img src="/assets/images/esports/logo.png" width="265" height="69" alt="Clash of Clans Wiki" class="header-logo__img"
                /></a>
              </div>

              <nav class="main-nav">
                <ul class="main-nav__list">
                  <li>
                    <router-link :to="`/`">Home</router-link>
                  </li>
                  <li>
                    <a href="#">Rankings</a>
                    <ul class="main-nav__sub">
                      <li>
                        <router-link :to="`/clans`">Clans</router-link>
                      </li>
                      <li>
                        <router-link :to="`/players`">Players</router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Buildings</a>
                    <ul class="main-nav__sub">
                      <li>
                        <router-link :to="`/buildings/army_buildings`">Army Buildings</router-link>
                      </li>
                      <li>
                        <router-link :to="`/buildings/defensive_buildings`">Defensive Buildings</router-link>
                      </li>
                      <li>
                        <router-link :to="`/buildings/resource_buildings`">Resource Buildings</router-link>
                      </li>
                      <li>
                        <router-link :to="`/buildings/other_buildings`">Other Buildings</router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Army</a>
                    <ul class="main-nav__sub">
                      <li>
                        <router-link :to="`/army/troops`">Troops</router-link>
                      </li>
                      <li>
                        <router-link :to="`/army/spells`">Spells</router-link>
                      </li>
                      <li>
                        <router-link :to="`/army/heroes`">Heroes</router-link>
                      </li>
                      <li>
                        <router-link :to="`/army/siege_machines`">Siege Machines</router-link>
                      </li>
                      <li>
                        <router-link :to="`/army/pets`">Pets</router-link>
                      </li>
                      <li>
                        <router-link :to="`/army/non-combatants`">Non-combatants</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div class="header__primary-spacer"></div>
              <div class="header-search-form">
                <form action="#" id="mobile-search-form" class="search-form">
                  <input
                    id="search_player"
                    type="text"
                    class="form-control header-mobile__search-control"
                    v-model="playerTag"
                    placeholder="Enter your Tag here..."
                  />
                  <button type="button" class="header-mobile__search-submit" name="btnSearch" @click="getPlayer">
                    <i class="fas fa-search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="site-content">
        <div class="container">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
      <footer id="footer" class="footer">
        <div class="sponsors-wrapper">
          <div class="container">
            <div class="sponsors">
              <ul class="sponsors-logos">
                <li class="sponsors__item">
                  <a href="https://www.youtube.com/GameXO?sub_confirmation=1" target="_blank"
                    ><img
                      src="/assets/images/esports/sponsor-darkgame.png" width="106" height="102"
                      alt="Game X"
                  /></a>
                </li>
                <li class="sponsors__item">
                  <a href="https://www.youtube.com/GameXO?sub_confirmation=1" target="_blank"
                    ><img
                      src="/assets/images/esports/sponsor-geniusgeek.png" width="108" height="83"
                      alt="GeniusGeek"
                  /></a>
                </li>
                <li class="sponsors__item">
                  <a href="https://themecenter.net" target="_blank"
                    ><img
                      src="/assets/images/esports/sponsor-ghostgames.png" width="118" height="66"
                      alt="GhostGames"
                  /></a>
                </li>
                <li class="sponsors__item">
                  <a href="https://devicecity.net" target="_blank"
                    ><img
                      src="/assets/images/esports/sponsor-gamechat.png" width="136" height="39"
                      alt="GameChat"
                  /></a>
                </li>
                <li class="sponsors__item">
                  <a href="https://xuyenmandem.com" target="_blank"
                    ><img
                      src="/assets/images/esports/sponsor-elitesound.png" width="98" height="89"
                      alt="eliteSound"
                  /></a>
                </li>
                <li class="sponsors__item">
                  <a href="https://dragonmania.net" target="_blank"
                    ><img
                      src="/assets/images/esports/sponsor-dragonfire.png" width="114" height="88"
                      alt="Dragon Mania Legends Club"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <div class="footer-widgets effect-duotone effect-duotone--base">
          <div class="footer-widgets__inner">
            <div class="container">
              <div class="row">
                <div class="col-12 col-lg-6"></div>
                <div class="col-sm-6 col-lg-3"></div>
                <div class="col-sm-6 col-lg-3"></div>
              </div>
            </div>
          </div>
        </div> -->
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClashOfClans",
  components: {},
  data() {
    return {
      playerTag: ``,
    }
  },
  methods: {
    getPlayer() {
      if (this.playerTag) {
        let tag = this.playerTag.toUpperCase()
        this.$router.push(`/players/${tag}`)
        localStorage.setItem(`playerTag`, tag)
      }
    },
    loadPlayerTag() {
      let playerTag = localStorage.getItem(`playerTag`)
      if (playerTag) {
        this.playerTag = playerTag
      }
    },
  },
  mounted() {
    this.loadPlayerTag()
  },
};
</script>
<style>
  #search_player {
    text-transform: uppercase;
  }
</style>

