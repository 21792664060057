import {
    createRouter,
    createWebHistory
} from 'vue-router'


const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../components/Home/Index')
    },
    {
        path: '/:type/:category',
        name: 'List',
        component: () => import('../components/Home/List')
    },
    {
        path: '/:type/:category/:code',
        name: 'Detail',
        component: () => import('../components/Home/Detail')
    },
    {
        path: `/clans`,
        name: 'Clans',
        component: () => import('../components/Clan/List')
    },
    {
        path: `/clans/:tag`,
        name: 'ClanDetail',
        component: () => import('../components/Clan/Detail')
    },
    {
        path: `/players`,
        name: 'Players',
        component: () => import('../components/Player/List')
    },
    {
        path: `/players/:tag`,
        name: 'PlayerDetail',
        component: () => import('../components/Player/Detail')
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router